import { useState, useEffect, useRef } from 'react';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import REQUESTS from '../../api/requests';
import { HOST } from '../../configs';
import ICONS from '../../configs/icons';

import Text from '../../components/Text';
import Loading from '../../components/Loading';
import MessageModal from '../../components/MessageModal';
import ValidateMacAddress from '../../components/ValidateMacAddress';

import Packages from './component/Packages';
import PaypalComponent from './component/PaypalComponent';
import StripeComponent from './component/StripeComponent';
import EmailModal from './component/EmailModal';

import styles from './index.module.scss';
import Button from '../../components/Button';
import ActivateByVoucherCode from './component/ActivateByVoucherCode';

export default function ActivationPage() {

    const [selectedPackage, setSelectedPackage] = useState(null);
    const [packages, setPackages] = useState([]);

    const [paymentSettings, setPaymentSettings] = useState(null);

    const [macValue, setMacValue] = useState("");
    const [macIsValid, setMacIsValid] = useState(false);
    const [device, setDevice] = useState(null);

    const [agreeWithAll, setAgreeWithAll] = useState(false);

    const [paymentMethodeDesabled, setPaymentMethodeDesabled] = useState(false);

    const [paymentType, setPaymentType] = useState(null);

    const [stripeSecretKey, setStripeSecretKey] = useState(null);

    const [loading, setLoading] = useState(false);

    const [openVoucherModal, setOpenVoucherModal] = useState(false);

    const cmiPaymentRow = useRef(null);

    const navigate = useNavigate();

    const [searchParams] = useSearchParams()

    const getPackages = () => {

        const callback = (message) => {
            setPackages(message.rows);

            const packageId = searchParams.get("package_id");
            const finded = message.rows.find((item) => item.id == packageId);
            const recomended = message.rows.find((item) => item.recommended);

            const selected = finded || recomended || message.rows[0];

            if (selected) {
                setSelectedPackage(selected);
            }
        };

        REQUESTS.GET_PACKAGES(callback);
    };

    const returnCustomId = () => {
        const code = searchParams.get("code");

        const customId = {
            t: "ds", //type
            pi: selectedPackage?.id, //package_id
            dm: macValue, //device_mac
        };

        if (code) {
            customId.rlc = code; // referral_link_code
        }

        return JSON.stringify(customId);
    };

    const getPaymentSettings = () => {
        REQUESTS.PAYMENT_SETTINGS((data) => {

            setPaymentSettings(data);

            if (!data?.stripe_enabled &&
                !data?.paypal_enabled &&
                !data?.cmi_enabled &&
                !data.dlocal_enabled &&
                !data.parcelado_enabled &&
                !data.ineco_enabled &&
                !data.coinbase_enabled &&
                !data.apple_pay_enabled &&
                !data.amazon_enabled &&
                !data.taza_pay_enabled) {
                setPaymentMethodeDesabled(true);
            } else {
                setPaymentMethodeDesabled(false);
            }

        });
    };

    const pay = (email) => {
        const code = searchParams.get("code");

        if (selectedPackage && macValue && email) {

            let cancel_url = `${HOST}payment-error`;
            let success_url = `${HOST}payment-success`;

            let host = "";

            if (paymentType === "dlocal") {
                host = `${HOST}payment/dlocal?type=device_subscription&package_id=${selectedPackage.id}&device_mac=${macValue}&email=${email}`
            } else if (paymentType === "coinbase") {
                host = `${HOST}payment/coinbase?type=device_subscription&package_id=${selectedPackage.id}&device_mac=${macValue}&email=${email}&cancel_url=${cancel_url}&success_url=${success_url}`
            } else if (paymentType === "parcelado-pay") {
                host = `${HOST}payment/parceladoUSA?type=device_subscription&package_id=${selectedPackage.id}&device_mac=${macValue}&email=${email}`
            } else if (paymentType === "tazapay") {
                host = `${HOST}payment/taza_pay?type=device_subscription&package_id=${selectedPackage.id}&device_mac=${macValue}&email=${email}`
            }

            if (code) {
                host += `& referral_link_code=${code} `;
            }

            if (host.length) {
                window.location.href = host;
            }
        }
    };

    const payWithCMI = (email, name) => {
        const code = searchParams.get("referral_link_code");

        const body = {
            email,
            name,
            mac: macValue,
            package_id: selectedPackage?.id,
            type: "device_subscription",
        };

        if (code) {
            body.referral_link_code = code;
        }

        REQUESTS.PAY_WITH_CMI(
            body,
            (data) => {
                for (let key in data) {
                    let input = document.createElement("input");
                    input.type = "hidden";
                    input.value = data[key];
                    input.name = key;
                    cmiPaymentRow.current.appendChild(input);
                }

                cmiPaymentRow.current.submit();
            },
            (err) => {
                // notification.error({
                //     message: "Error",
                //     description: err.message,
                // });
            }
        );
    };

    const payByPaypal = () => {
        navigate("/payment-success");
    };

    const payByStripe = () => {
        setLoading(true);

        const code = searchParams.get("code");

        if (selectedPackage && macValue) {
            let host = `${HOST}payment/stripe/checkout?type=device_subscription&package_id=${selectedPackage.id}&device_mac=${macValue}`;

            if (code) {
                host += `&referral_link_code=${code}`;
            }

            window.location.href = host;

            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const getStripeSecretKey = () => {
        REQUESTS.GET_STRIPE_SECRET_KEY(
            (data) => {
                setStripeSecretKey(data);
            },
            (err) => { },
            selectedPackage.id,
            macValue //mac
        );
    };

    useEffect(() => {
        if (paymentSettings?.stripe_enabled && selectedPackage && macIsValid) {
            getStripeSecretKey();
        }
    }, [selectedPackage, macIsValid]);

    useEffect(() => {
        if (searchParams.get("mac")) {
            setMacValue(searchParams.get("mac"));
        }

        getPackages();
        getPaymentSettings();
    }, []);

    return (
        <div className={styles['activation-page']}>
            <div className="wrapper">
                <h1 className={styles['title']}><Text page="activation" text='page_name' /></h1>
                <p className={styles['description']}><Text page="activation" text='choose_plan_text' /></p>
                <div className={styles['page-content']}>
                    <Packages packages={packages} currency={paymentSettings?.currency} onClick={setSelectedPackage} selectedPackage={selectedPackage} />
                    <ValidateMacAddress
                        value={macValue}
                        setMacIsValid={setMacIsValid}
                        setDevice={setDevice}
                        setValue={setMacValue}
                    />
                    <div className={styles['privacy-text-section']}>
                        <p>
                            <input type="checkbox" checked={agreeWithAll} onChange={(e) => setAgreeWithAll(!agreeWithAll)} />
                            {" "} <Link to="/privacy-policy">
                                <Text page="activation" text='agree_with_all' />
                            </Link>
                        </p>
                        <Text page="activation" text='agree_with_all_description' />
                    </div>

                    {paymentMethodeDesabled && <p className={styles['error-message']}><Text page='activation' text="payments_methods_disabled" /></p>}
                    {
                        agreeWithAll && macIsValid && !device?.payed && selectedPackage && paymentSettings &&
                        <div>
                            {
                                paymentSettings.paypal_enabled &&
                                <PaypalComponent
                                    customId={returnCustomId()}
                                    type={
                                        (selectedPackage?.schedule === "lifetime" || selectedPackage.schedule === "one year")
                                            ? "order"
                                            : "subscription"
                                    }
                                    clientId={paymentSettings?.paypal_client_id}
                                    deviceId={device?.id}
                                    currency={paymentSettings?.currency}
                                    selectedPackage={selectedPackage}
                                    onApprove={payByPaypal}
                                    paymentSettings={paymentSettings}
                                />
                            }

                            {
                                paymentSettings.stripe_enabled &&
                                ((selectedPackage.schedule === "lifetime" || selectedPackage.schedule === "one year") ?
                                    paymentSettings.stripe_public_key && stripeSecretKey &&
                                    <StripeComponent
                                        paymentSettings={paymentSettings}
                                        stripeSecretKey={stripeSecretKey}
                                    />
                                    :
                                    <div
                                        onClick={payByStripe}
                                        className={styles['stripe-button']}
                                    >
                                        {loading && <Loading />} {<Text page="activation" text="stripe_button_text" /> || ICONS.STRIPE}
                                    </div>
                                )
                            }

                            {
                                paymentSettings.cmi_enabled &&
                                <div className={styles["cmi-payment"]} onClick={() => setPaymentType("cmi")} >PAY NOW</div>
                            }

                            {
                                paymentSettings.dlocal_enabled &&
                                <div
                                    onClick={() => setPaymentType("dlocal")}
                                    className={styles["pixi-payment-button"]}
                                >
                                    Pixi pay
                                </div>
                            }

                            {paymentSettings.coinbase_enabled &&
                                <div
                                    className={styles['coinbase-button']}
                                    onClick={() => setPaymentType("coinbase")}
                                >
                                    {ICONS.COINBASE}
                                    Pay with Coinbase
                                </div>
                            }
                            {
                                paymentSettings.parcelado_enabled &&
                                <div className={styles['parcelado-usa']} onClick={() => setPaymentType("parcelado-pay")} >Pagar com pix</div>
                            }
                            {
                                paymentSettings.taza_pay_enabled &&
                                <div className={styles['tazapay']} onClick={() => setPaymentType("tazapay")} >Tazapay</div>
                            }
                        </div>
                    }
                </div>
                <div className={styles['activate-by-voucher-wrapper']}>
                    <div className={styles['activate-by-voucher']}>
                        <Button onClick={() => setOpenVoucherModal(true)} styleType="grey"><Text text="activate_qa_button_text" page='activation' /></Button>
                        <p className={styles['voucher-text']}><Text page="activation" text="activate_for_qa_description" /></p>
                    </div>
                </div>
                <ActivateByVoucherCode open={openVoucherModal} onClose={() => setOpenVoucherModal(false)} />
            </div>

            <form
                ref={cmiPaymentRow}
                method={"post"}
                action={paymentSettings?.geteway_url}
            ></form>

            <EmailModal
                open={paymentType}
                onClose={() => setPaymentType(null)}
                pay={paymentType === "cmi" ? payWithCMI : pay}
            />

            <MessageModal
                message={{ text: "This  device already has activated package!", type: false }}
                open={macIsValid && device?.payed}
                onClose={() => {
                    setMacIsValid(false);
                    setDevice(null);
                    setMacValue("");
                }}
            />
        </div >
    )
}
