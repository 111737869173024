import { useEffect } from "react";

import { Route, Routes, useNavigate, Navigate } from "react-router-dom";

import Layout from "./layout/Layout";
import HomePage from "./pages/home";
import NewsPage from "./pages/news";
import LoginPage from "./pages/login";
import ResellerPage from "./pages/reseller";
import PlaylistsPage from "./pages/playlists";
import ContactUsPage from "./pages/contact-us";
import PartnerInfoLink from "./pages/info-link";
import ActivationPage from "./pages/activation";
import PrivacyPolicy from "./pages/privacy-policy";
import PaymentError from "./pages/payment-error";
import PaymentSuccess from "./pages/payment-success";
import CurrentNews from "./pages/news/component/CurrentNews";
import UnderConstruction from "./pages/under-construction";

import useStore from "./hooks/useStore";

import REQUESTS from "./api/requests";

import { setWebSettings } from "./store/feautures/webSettingsSlice";
import { setAppInfo } from "./store/feautures/appInfoSlice";

import "./styles/global.scss";

function App() {
  const [store, setStore] = useStore("webSettings");
  const [appInfoRedux, setAppInfoRedux] = useStore("webSettings");
  const { navigate } = useNavigate();

  const getWebSettings = () => {
    REQUESTS.GET_WEB_SETTINGS((response) => {
      setStore(setWebSettings(JSON.parse(response.json_configs)));
    })
  }

  const getAppInfo = () => {
    function callback(data) {
      setAppInfoRedux(setAppInfo(data));
      if (data.under_construction) {
        navigate("/under_construction");
      }
    }

    function errorCallback(err) {
      navigate("/under_construction");
      window.location.href = "/#/under_construction";
    }

    REQUESTS.APP_INFO(callback, errorCallback);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  useEffect(() => {
    getWebSettings();
    getAppInfo();
  }, []);

  useEffect(() => {
    scrollToTop();
  })

  return <>
    <Routes >
      <Route path="/" element={<Layout />} >
        <Route path="/" element={<HomePage />} />
        <Route path="/activation" element={<ActivationPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/news/:id" element={<CurrentNews />} />
        <Route path="/reseller" element={<ResellerPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/playlists" element={<PlaylistsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/info-link" element={<PartnerInfoLink />} />
        <Route path="/under_construction" element={<UnderConstruction />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-error" element={<PaymentError />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  </>
}

export default App;
