import { useEffect, useState } from "react";

import useTranslation from "../hooks/useTranslation";

import REQUESTS from "../api/requests";

import TEXT from "../configs/text";

import Message from "./Message";

import styles from "./styles/Input.module.scss";

export default function ValidateMacAddress({
  setMacIsValid,
  value = "",
  setValue,
  setDevice = () => { },
}) {
  const translation = useTranslation();

  const [message, setMessage] = useState({
    text: "",
    type: true,
  });

  const handleText = (text) => {
    if (translation?.general?.[text]) {
      return translation?.general?.[text];
    }

    return TEXT?.general?.[text];
  };

  const validateMacAddress = () => {

    if (value?.length === 0) {
      return;
    }

    function callback(data) {
      if (setDevice) {
        setDevice(data);
      }

      setMacIsValid(true);
      setMessage({
        text: handleText("mac_input_valid_message"),
        type: true,
      });
    }

    function errorCallback(error) {
      setMacIsValid(false);
      setMessage({
        text: handleText("mac_input_not_valid_message"),
        type: false,
      });
    }

    REQUESTS.VALIDATE_MAC(value, callback, errorCallback);
  };

  const generateMacFormat = (e) => {
    var mac = e.target.value.replace(/[^0-9a-z]/gi, "");
    var result = "";

    for (var i = 0; i < mac.length; i++) {
      if (i % 2 === 0 && i !== 0) {
        result += ":";
      }
      result += mac[i];
    }
    setValue(result.substring(0, 17));
  };

  useEffect(() => {

    if (value?.length === 17) {
      validateMacAddress();
    } else {
      setMacIsValid(false);
      setMessage({
        text: "",
        type: false,
      });
    }

  }, [value]);

  return (
    <div className={styles["input-container"]}>
      <input
        autoComplete="off"
        value={value}
        onChange={generateMacFormat}
        placeholder={handleText("mac_input_placeholder")}
        name="mac"
      />
      <Message message={message} />
    </div>
  );
}